<template>
  <div class="r-map-balloon">
    <router-link
      :to="{
        name: 'edit',
        params: { id: `${id}`},
      }"
    >
      <div class="pointer">
        <img
          v-if="img"
          class="r-map-balloon__img"
          :src="img"
          :alt="title"
        >
        <div class="r-map-balloon__info padding_b">
          <p class="roquefort">
            {{ title }}
          </p>
          <p class="mt-1 сaprino mozzarella opacity-72">
            {{ address }}
          </p>
        </div>
      </div>
    </router-link>
    <div class="r-map-balloon__info padding_t">
      <div class="mt-2">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    title: {
      type: String
    },
    address: {
      type: String
    },
    img: {
      type: String
    },
    id: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  max-height: 400px;
  &__img {
    width: 100%;
    height: 122px;
  }
  &__info {
    padding: 16px;
  }
}
.pointer{
  cursor: pointer;
}
.padding_b{
  padding-bottom: 0px;
}
.padding_t{
  padding-top: 0px;
}
</style>
