<template>
  <div>
    <loader v-if="isLoading" />
    <div
      class="objects__list mt-6"
      v-else
      :key="isCheck"
    >
      <div
        v-for="item in filteredObjects"
        v-if="item"
      >
        <card
          ref="Card"
          :service-title="item.title"
          :address="item.address"
          :photo0="item.photo1"
          :is-mod="Mod"
          :id="item.id"
          class="color-card"
        >
          <div class="flex">
            <div class="mr-3 float flex mozzarella">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 3V13C0 14.6569 1.34315 16 3 16H15C15.5523 16 16 15.5523 16 15V3C16 2.44772 15.5523 2 15 2H7C6.44772 2 6 2.44772 6 3C6 3.55228 6.44772 4 7 4H10V6H8C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8H10V10H8C7.44772 10 7 10.4477 7 11V14H3C2.44772 14 2 13.5523 2 13C2 12.4477 2.44772 12 3 12H4C4.55228 12 5 11.5523 5 11V1C5 0.447715 4.55228 0 4 0H3C1.34315 0 0 1.34315 0 3ZM2 3C2 2.44772 2.44772 2 3 2V10C2.64936 10 2.31278 10.0602 2 10.1707V3ZM12 11C12 11.5523 11.5523 12 11 12H9V14H14V4H12V11Z"
                  fill="#3D75E4"
                />
              </svg>
              <div class="text__date ml-1 overHidden maxWidith">
                {{ item.areaS }} м&sup2;
              </div>
            </div>
            <div class="mr-1 float flex mozzarella text__date">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 0C11.3954 0 10.5 0.89543 10.5 2V3.5H9C7.89543 3.5 7 4.39543 7 5.5V6H5.5C4.39543 6 3.5 6.89543 3.5 8V8.5H2C0.895431 8.5 0 9.39543 0 10.5V15C0 15.5523 0.447715 16 1 16H3.48902C3.51352 16.0003 3.53801 15.9996 3.56244 15.9981C3.6816 15.9907 3.79512 15.9625 3.89938 15.9171C4.03795 15.8568 4.16492 15.7642 4.26825 15.6402L6.3313 13.1645L7.92279 13.695C8.81208 13.9915 9.78794 13.6305 10.2702 12.8267L11.4609 10.8422C11.6165 10.9421 11.8016 11 12.0002 11C12.5525 11 13.0002 10.5523 13.0002 10V8C13.0002 7.82177 12.9528 7.65007 12.8666 7.50054C12.8122 7.40612 12.742 7.31972 12.6569 7.24578C12.6163 7.21045 12.5726 7.17815 12.526 7.14935C12.483 7.1228 12.4385 7.09977 12.3928 7.08027C12.187 6.99205 11.9635 6.97818 11.7566 7.03011C11.6798 7.04939 11.6052 7.07775 11.5347 7.11474L9.55301 8.10557C9.05903 8.35256 8.8588 8.95324 9.10579 9.44721C9.22656 9.68875 9.43188 9.86005 9.66794 9.94318L8.55525 11.7977L6.96376 11.2672C6.18271 11.0068 5.32192 11.2517 4.79486 11.8842L3.03167 14H2V10.5H3.5C4.60457 10.5 5.5 9.60457 5.5 8.5V8H7C8.10457 8 9 7.10457 9 6V5.5H10.5C11.6046 5.5 12.5 4.60457 12.5 3.5V2H14V15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15V2C16 0.895431 15.1046 0 14 0H12.5Z"
                  fill="#3D75E4"
                />
              </svg>
              <div class="text__date ml-1">
                {{ item.floorS }}
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <not-found v-show="countRow < 1 && isSearch" />
  </div>
</template>

<script>
import infiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
import _ from 'lodash';
import notFound from './notFound';
import Card from '@/components/ObjectCardMer.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'LoadMer',
  components: {
    Loader,
    Card,
    notFound,
    infiniteLoading
  },
  props: {
    activeTabId: {
      type: String,
      default: ''
    },
    checkHouse: {
      type: Number,
      default: null
    },
    checkSort: {
      type: Number,
      default: null
    },
    isSearch: {
      type: String,
      default: ''
    },
    isSearchStatus: {
      type: String,
      default: ''
    },
    selectedCategoryMer: {
      type: String,
      default: ''
    },
    Mod: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 2,
      list: [],
      infiniteLoaded: false,
      isCheck: 0,
      deadAxios: true,
      pageUrl: null,
      count: 0,
      countSearch: 0
    };
  },
  computed: {
    // Загрузка списка домов
    allObjects() {
      const _this = this;
      if (!this.$store.state?.active?.all.length) return [];

      const objects = this.$store.state.active.all;
      return objects
        .map(el => {
          if (el?.id) {
            return {
              id: el.id,
              address: el.address ? el.address : '-',
              floorS: el.floorS,
              areaS: el.areaS,
              title: el.title ? el.title : '',
              lan: el.lat ? el.lat : 47.521196,
              lng: el.lng ? el.lng : 42.180914,
              coordinates: [el.lat, el.lng],
              inApproval: el.inApproval,
              type: el.type,
              photo1: el.photo1 ? el.photo1 : null,
              hidden: el.hidden,
              created: el.created
            };
          }
        })
        .sort((a, b) => (b.count < 20) && a.address.localeCompare(b.address));
    },

    // Фильтрация списка домов
    filteredObjects() {
      const v = this;
      v.list = this.allObjects;

      if (v.activeTabId == 'moderation') {
        v.list = v.list.filter(
          el => (el.inApproval == 1)
        );
      }
      if (v.activeTabId == 'published') {
        v.list = v.list.filter(
          el => (el.inApproval == 0)
        );
      }

      v.list = v.list.filter(el => (el.hidden == 0));

      if (this.checkHouse == 2) {
        v.list = v.list.filter(el => ((el.type == 1)));
      }
      if (this.checkHouse == 3) {
        v.list = v.list.filter(el => ((el.type == 2)));
      }
      if (this.checkSort == 2) {
        v.list = v.list.sort((a, b) => ((a.title > b.title) ? 1 : -1));
      }
      if (this.checkSort == 3) {
        v.list = v.list.sort((a, b) => new Date(b.created) - new Date(a.created));
      }
      if (v.isSearch) {
        v.list = v.list.filter(
          el => (el.title ? (el.title.toLowerCase()
            .indexOf(v.isSearch.toLowerCase()) >= 0) : false)
        );
      }

      return v.list;
    },
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    countRow() {
      return this.filteredObjects.length;
    }
  },
  mounted() {
  /*  let v = this;
    onscroll = function(){
      var scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
      if(window.scrollY+1 >= scrollHeight - innerHeight) {
        v.eventHandler();
      }
    };

   */
  },
  methods: {
    /*
    eventHandler() {
      const v = this;
      if (v.deadAxios) {
          axios.post(`/ajax.php?action=getRegions&page=${v.page}pageSize=50`, {
            currentMainMenuNumber: 38
          }).then(({data}) => {
            this.count = data.count.count;
            if (Math.ceil(data.count.count / 50) <= v.page) {
              console.log(Math.ceil(data.count.count / 50));
              v.deadAxios = false;
            }
            v.page++;
            v.list.push(...data.all);
            v.isCheck++;
          });
      }
    },
  */
  }
};
</script>

<style lang="scss" scoped>
.text__date {
  color: #0e1420;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.objects {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }
}
.maxWidith {
  max-width: 100px;
}
.overHidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.color-card {
  color: #0e1420;
}
</style>
