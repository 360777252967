import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cleaningList: [],
    // ----
    active: null,
    types: null,
    objects: null,
    selectedObject: null,
    save: null,
    saveNew: null,
    saveDouble: null,
    isObjectLoading: null,
    saveApprove: null,
    comments: null,
    commentsSave: null,
    deleteComm: null,
    all: null,
    isEmpty: false,
    delete: null,
    email: null
  },
  mutations: {
    setActive(state, objects) {
      state.active = objects;
    },
    setSaveNew(state, types) {
      state.saveNew = types;
    },
    setSaveDouble(state, types) {
      state.saveDouble = types;
    },
    setDelete(state, types) {
      state.delete = types;
    },
    setDeleteEmail(state, types) {
      state.email = types;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    }
  },
  getters: {
    getActive: () => state.objects,
    getSaveNew: () => state.objects,
    getSaveDouble: () => state.objects,
    getDelete: () => state.objects,
    getDeleteEmail: () => state.objects,
    getAll: () => state.objects
  },
  actions: {
    async loadActive(ctx, paylod) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getActive(paylod);
      ctx.commit('setActive', res);

      ctx.commit('setObjectLoading', false);
      return res;
    },

    async loadActivity(ctx, paylod) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getActivity(paylod);
      ctx.commit('setActive', res);
      ctx.commit('setObjectLoading', false);

      return res;
    },
    async loadSaveNew(ctx, payload) {
      console.log('sdfsdfsd', payload);
      const res = await api.setSaveNew(payload);
      ctx.commit('setSaveNew', res);

      return res;
    },
    async loadSaveDouble(ctx, payload) {
      const res = await api.setSaveDouble(payload);
      ctx.commit('setSaveDouble', res);

      return res;
    },
    async loadDelete(ctx, payload) {
      const res = await api.setDelite(payload);
      ctx.commit('setDelete', res);

      return res;
    },
    async loadDeleteEmail(ctx, payload) {
      const res = await api.setDeliteEmail(payload);
      ctx.commit('setDeleteEmail', res);

      return res;
    }
  }
});
