<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload">
            <h1 class="ricotta mb-1">
              Сохранить изменения?
            </h1>
            <div class="date">
              Вы внесли изменения в объект
            </div>
            <div class="flex mt-2 mb-4 notSave">
              <r-button
                class="mt-8 mr-3"
                style="background: #E4EDFB;color: #214EB0;"
                @click="Close"
                title="Не сохранять"
                width="wide"
              />
              <r-button
                class="mt-8"
                @click="Save"
                title="Сохранить"
                width="wide"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RirMap from './RirMap.vue';

export default {
  name: 'Save',
  components: {
    RirMap
  },
  data() {
    return {
      upload: false,
      json: null,
      info: 'save'
    };
  },
  methods: {
    Close() {
      this.upload = false;
    },
    uploadContainer(json, info) {
      this.upload = true;
      this.json = json;
      this.info = info;
    },
    Save() {
      const _this = this;
      if (_this.$route.params.double == 'true' || this.info == 'new') {
        if (_this.json) {
          _this.$store.dispatch('loadSaveDouble', _this.json).then(result => {
            const r = _this.$router.resolve({
              name: 'index'
            });
            if (!result?.error) {
              window.location.assign(r.href);
            }
          }, error => {
            console.error(error);
          });
        }
      } else if (_this.json) {
        _this.$store.dispatch('loadSaveNew', _this.json).then(result => {
          if (this.info == 'savePub') {
            const r = _this.$router.resolve({
              name: 'index'
            });
            if (!result?.error) {
              window.location.assign(r.href);
            }
          } else {
            /* let r = _this.$router.resolve({
                name: 'edit',
                params: {id: _this.$route.params.id},
              }); */
            const r = _this.$router.resolve({
              name: 'index'
            });
            if (!result?.error) {
              window.location.assign(r.href);
            }
          }
        }, error => {
          console.error(error);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button_one {
  width: calc(50% - 15px);
  float: left;
}
.button_too {
  width: calc(50% - 15px);
  float: right;
}
.date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.48;
}

.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 40%;
  margin-left: 31%;
}
.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}

.notSave {
  width: 100%;
}
</style>
