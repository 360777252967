<template>
  <div class="objects">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div class="parent align-center">
          <div
            class="container-upload"
            v-if="!load"
          >
            <h1 class="ricotta mb-6">
              Новые данные
            </h1>
            <upload-file
              ref="childComponent"
              @uploadEvent="uploadFile"
              @uploadError="uploadError"
              :accept-file="['.xls, .xlsx, .csv']"
              :count-file="2"
              upload-url="/ajax.php?action=universalImport"
              :value="[]"
              :count-line="1"
              description="или перетяните сюда файл csv, созданый на основе шаблона"
            />
            <a
              class="flex sulguni mt-6"
              href="https://base.rosatom.city/ajax.php?getImportXLStemplateForLayer=sarovyama_rents"
              style="color: #3d75e4;"
            >
              <r-icon
                icon="load"
                class="mr-1"
              />
              Скачать шаблон для импорта данных в csv
            </a>
            <div class="flex mt-8">
              <r-button
                class="flex-1"
                @click="clickUpload"
                width="wide"
                title="Загрузить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="ricotta mb-7">
      Площади в аренду
    </h1>
    <r-tabs
      class="mt-7"
      :items="tabItems"
      v-model="activeTabId"
    />
    <loader v-if="isLoading" />
    <empty
      v-else-if="countRow < 1 && isCheckHouse <= 1 && isCheckSort <= 1 &&
        !searchText && activeTabId.id == 'moderation'"
    />
    <div
      v-show="!this.$store.state.isEmpty"
      v-else
    >
      <div class="mt-6 flex">
        <router-link
          class=" mr-5 flex align-center sulguni rocky--text"
          :to="{ name: 'new' }"
        >
          <r-icon
            class="mr-2"
            icon="add"
            size="16"
          />
          Добавить вручную
        </router-link>
        <div
          class=" mr-5 flex align-center sulguni rocky--text pointer"
          @click="uploadContainer"
        >
          <r-icon
            class="mr-2"
            icon="add"
            size="16"
          />
          Загрузить данные
        </div>
      </div>
      <div class="objects__filter">
        <r-select
          :value="isCheckHouse"
          class="flex-1 mr-6"
          :items="sortTypes"
          label="Тип объекта"
          @change="onChangeType"
        />
        <r-select
          :value="1"
          class="flex-1"
          :items="sortSort"
          label="Сортировать"
          @change="onChangeSort"
        />
        <r-input
          class="flex-1 ml-6"
          label="Поиск"
          v-model="searchText"
          @input="searchInfo()"
          before-icon="search"
        />
        <div class="flex ml-6">
          <r-button-simple
            size="larishae"
            icon="icon-view"
            icon-size="20"
            @click="selectedView = 'table'"
            :type="selectedView === 'map' ? 'secondary' : 'primary'"
          />
          <r-button-simple
            v-show="activeTabId.id !== 'moderation'"
            size="larishae"
            class="ml-2"
            icon="geopoint"
            icon-size="20"
            :type="selectedView === 'map' ? 'primary' : 'secondary'"
            @click="loadItem"
          />
        </div>
      </div>
      <template>
        <div class="mt-6 flex">
          <div
            class="ml-auto parmigiano text-titanic opacity-48"
            :key="countLine"
          >
            {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countRow }}
          </div>
        </div>
        <empty
          v-if="countRow < 1 && isCheckHouse <= 1 && isCheckSort <= 1 &&
            !searchText && activeTabId.id == 'published' && selectedView === 'table'"
        />
        <div
          v-if="(activeTabId.id == 'published' || activeTabId.id == 'moderation') && selectedView === 'table'"
        >
          <loadCardMer
            @my-event="filledInput"
            :key="countSearch"
            :active-tab-id="activeTabId.id"
            :check-house="isCheckHouse"
            :check-sort="isCheckSort"
            :is-search="searchText"
            :mod="activeTabId.id == 'moderation' ? true : false"
          />
        </div>
        <div
          v-show="selectedView === 'map'"
          :key="countSearch"
          class="objects__map mt-6"
          ref="map"
        >
          <rir-map v-if="selectedView === 'map'">
            <ymap-marker
              v-for="marker in filteredObjects"
              v-if="renderComponent"
              :key="marker.id"
              :coords="[`${marker.lan}`, `${marker.lng}`]"
              :cluster-name="'main'"
              :marker-id="'marker'"
              @click="onMarkerClick(marker.id)"
              @balloonopen="bindListener"
              :options="{
                hideIconOnBalloonOpen: false,
                draggable: false
              }"
              :icon="$markerIconTwo"
            >
              <balloon-card
                slot="balloon"
                :id="marker.id"
                :item="marker"
                :title="marker.title"
                :address="marker.address"
                :img="marker.photo1 ? marker.photo1 : null"
                :data-id="marker.id"
              >
                <div class="flex justify-space-between">
                  <router-link
                    :to="{
                      name: 'edit-double',
                      params: { id: marker.id, double: true},
                    }"
                  >
                    <button
                      class="flex opacity-72"
                      transparent
                      contentWidth
                      data-action="copy"
                    >
                      <r-icon
                        class="mr-2"
                        icon="copy"
                        fill="rocky"
                        size="16"
                      />
                      <div class="briscola rocky--text">
                        Дублировать
                      </div>
                    </button>
                  </router-link>
                  <button
                    transparent
                    contentWidth
                    data-action="delete"
                  >
                    <r-icon
                      class="opacity-72"
                      icon="delete"
                      fill="fargo"
                      size="16"
                    />
                  </button>
                </div>
              </balloon-card>
            </ymap-marker>
          </rir-map>
        </div>
      </template>
    </div>
    <delete-warn-modal ref="childDeleteBalloon" />
  </div>
</template>

<script>
import RirMap from '@/components/RirMap.vue';
import loadCardMer from '../components/loadMer.vue';
import Empty from '../components/Empty';
import BalloonCard from '@/components/BalloonCard';
import DeleteWarnModal from '@/components/DeleteWarnModal';
import UploadFile from '@/components/uploadFile.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    UploadFile,
    RirMap,
    loadCardMer,
    BalloonCard,
    Empty,
    DeleteWarnModal
  },
  data() {
    return {
      sortTypes: [
        {
          id: 1,
          title: 'Все'
        },
        {
          id: 2,
          title: 'Муниципальные'
        },
        {
          id: 3,
          title: 'Коммерческие'
        }
      ],
      sortSort: [
        {
          id: 1,
          title: ''
        },
        {
          id: 2,
          title: 'По названию'
        },
        {
          id: 3,
          title: 'По дате'
        }
      ],
      load: false,
      upload: false,
      isCheckHouse: 1,
      isCheckSort: 1,
      countSearch: 0,
      selectedView: 'table',
      activeTabId: {
        id: 'published',
        title: 'Опубликовано',
        count: this.countOnPublic
      },
      countOnPublic: null,
      countOnModerate: 2,
      searchText: null,
      isShowMap: false,
      page: 1,
      list: 0,
      listTrue: true,
      listAdd: 0,
      renderComponent: true,
      count: 0,
      countLine: 0,
      listCount: [],
      selectedMarkerId: null
    };
  },
  computed: {
    countRow() {
      return this.filteredObjects.length;
    },
    // Загрузка списка домов
    allObjects() {
      const _this = this;
      if (_this.listTrue) {
        if (!_this.$store.state?.active?.all?.length) return [];
        const objects = _this.$store.state.active.all;
        this.listTrue = false;

        return objects
          .map(el => {
            if (el?.id) {
              return {
                id: el.id,
                address: el.address ? el.address : '-',
                floorS: el.floorS,
                title: el.title ? el.title : '',
                lan: el.lat ? el.lat : 47.521196,
                lng: el.lng ? el.lng : 42.180914,
                coordinates: [el.lat, el.lng],
                inApproval: el.inApproval,
                type: el.type,
                photo1: el.photo1 ? `${el.photo1}` : null,
                hidden: el.hidden,
                created: el.created
              };
            }
          })
          .sort((a, b) => (b.count < 20) && a.address.localeCompare(b.address));
      }
    },
    // Фильтрация списка домов
    filteredObjects() {
      const _this = this;
      let list = [];
      if (_this.listAdd == 0) {
        _this.listAdd = _this.allObjects;
      }

      list = _this.listAdd;
      list = list.filter(el => (el.hidden == 0));

      _this.listCount = list.filter(
        el => (el.inApproval == 1)
      );

      if (_this.activeTabId.id == 'moderation') {
        list = list.filter(
          el => (el.inApproval == 1)
        );
      }
      if (_this.activeTabId.id == 'published') {
        list = list.filter(
          el => (el.inApproval == 0)
        );
      }
      if (this.isCheckHouse == 2) {
        list = list.filter(el => ((el.type == 1)));
      }
      if (this.isCheckHouse == 3) {
        list = list.filter(el => ((el.type == 2)));
      }
      if (this.isCheckSort == 2) {
        list = list.sort((a, b) => ((a.title > b.title) ? 1 : -1));
      }
      if (this.isCheckSort == 3) {
        list = list.sort((a, b) => new Date(b.created) - new Date(a.created));
      }
      if (_this.searchText) {
        list = list.filter(
          el => (el.title ? (el.title.toLowerCase()
            .indexOf(_this.searchText.toLowerCase()) >= 0) : false)
        );
      }
      if (this.$store.state?.active?.count?.count && !_this.count) {
        _this.count = this.$store.state.active.count.count;
      } else {
        _this.count = list.length;
      }
      return list;
    },
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    tabItems() {
      const tabs = [
        {
          id: 'published',
          title: 'Опубликовано',
          count: this.countOnPublic
        },
        {
          id: 'moderation',
          title: 'На модерации',
          count: this.listCount.length
        }
      ];
      return tabs;
    }
  },

  async mounted() {
    this.$store.dispatch('loadActive', { page: false });
  },
  methods: {
    clickUpload(items) {
      this.$refs.childComponent.handleFiles();
    },
    uploadContainer() {
      this.load = false;
      this.upload = true;
    },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },
    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;
      const buttons = this.$refs.map.querySelectorAll(
        `.rir-map-balloon[data-id="${this.selectedMarkerId}"] .rir-button`
      );
      // rir-map-balloon

      const balloon = this.$refs.map.querySelector(`.rir-map-balloon[data-id="${this.selectedMarkerId}"]`);
      console.log(this.selectedMarkerId);
      buttons.forEach(button => {
        if (button.getAttribute('data-action') === 'copy') {
        } else {
          button.addEventListener('click', this.deleteRentBalloon);
        }
      });
    },
    deleteRentBalloon() {
      this.$refs.childDeleteBalloon.deleteContainer(this.selectedMarkerId);
    },
    uploadFile() {
      this.upload = false;
      this.showUpload = true;
      setTimeout(this.showUp, 4000);
    },
    uploadError() {
      this.error = true;
      this.upload = false;
      this.showUpload = true;
      setTimeout(this.showUp, 10000);
    },
    // Поск по типу дома
    async onChangeType(type) {
      this.isCheckHouse = type;
      this.countSearch++;
    },
    // Поск по типу дома
    async onChangeSort(sort) {
      this.isCheckSort = sort;
      this.countSearch++;
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    filledInput(item) {
      this.list = item;
    },
    searchInfo() {
      this.forceRerender();
      this.countSearch++;
    },
    loadItem() {
      this.selectedView = 'map';
      if (this.listAdd == 0) {
        this.$store.dispatch('loadActive', { page: false });
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}
.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}
.deleteAll {
  margin-right: 16px;
}
.pointer {
  cursor: pointer;
}
.upload-color {
  color: #3d75e4;
  cursor: pointer;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.container-upload {
  width: 87%;
  height: 300px;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 50%;
  margin-left: 33%;
  margin-top: -150px;
}
.justify-space-between {
  justify-content: space-between;
}
</style>
