<template>
  <div
    class="delete-warn"
    v-show="deleteModel"
  >
    <button
      @click="deleteModel = !deleteModel"
      class="delete-warn__close pointer"
      transparent
    >
      <r-icon
        icon="close"
        size="20"
        fill="rocky"
      />
    </button>

    <div class="delete-warn__wrapper">
      <h2 class="delete-warn__title">
        Комментарий для автора
      </h2>
      <r-select
        class="mt-6 flex-1 delete-warn__select"
        style=""
        :items="objectDelete"
        label="Причина"
        :value="itemsDef"
        v-model="itemsDef"
      />
      <r-textarea
        class="mt-6"
        label="Комментарий"
        :rows="8"
        v-model="message"
      />
      <div class="delete-warn__buttons">
        <r-button
          @click="deleteItem"
          title="Отправить"
          width="wide"
        />
      </div>
      <p class="delete-warn__text">
        Комментарий будет отправлен автору на имейл
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteWarnModal',
  data() {
    return {
      objectDelete: [
        { id: '1', title: 'Нарушение правил портала' },
        { id: '2', title: 'Описание не соответствует' },
        { id: '3', title: 'Изображения плохого качества' },
        { id: '4', title: 'Объект уже добавлен' },
        { id: '5', title: 'Другое' }

      ],
      id: false,
      deleteModel: false,
      message: '',
      itemsDef: 0
    };
  },

  methods: {
    async deleteContainer(id) {
      this.id = id;
      this.deleteModel = true;
    },
    deleteItem() {
      const _this = this;
      _this.$store.dispatch('loadDeleteEmail', { comment: _this.message, id: _this.id }).then(result => {
        _this.$store.dispatch('loadDelete', _this.id).then(result => {
          const r = _this.$router.resolve({
            name: 'index'
          });
          if (!result?.error) {
            window.location.assign(r.href);
          }
        }, error => {
          console.error(error);
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.delete-warn {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--rir-amelie);
  z-index: 49;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__close {
    position: absolute;
    top: 12px;
    right: 28px;
  }

  &__wrapper {
    max-width: 584px;
    width: 100%;
  }

  &__title {
    font-size: 32px;
    margin-bottom: 12px;
  }
  &__select {
    width: 50%;
  }
  &__text {
    opacity: 0.48;
    float: right;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-right: 32px;
    margin-top: 8px;
  }

  &__buttons {
    display: flex;
    margin-top: 32px;

    .rir-button {
      white-space: nowrap;
    }
  }
}
</style>
