import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getActive(paylod) {
    let page = '';
    if (paylod.page) {
      page = `&page=${paylod.page}&pageSize=50`;
    }
    const { data } = await this._axios.post(`/ajax.php?action=getRegions${page}`, { withHidden: 1, currentMainMenuNumber: 38 });

    return data;
  }

  async getActivity(payload) {
    const { data } = await this._axios.put('/ajax.php?action=getRegions', payload);

    return data;
  }

  async setSaveNew(item) {
    const { data } = await this._axios.post('/ajax.php?action=operateRegion', {
      id: item.id,
      action: 'update',
      currentMainMenuNumber: 38,
      item
    });
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async setSaveDouble(item) {
    const { data } = await this._axios.post('/ajax.php?action=operateRegion', {
      id: -1,
      action: 'update',
      currentMainMenuNumber: 38,
      item
    });
  }

  async setDelite(id) {
    const { data } = await this._axios.post('/ajax.php?action=operateRegion', {
      id,
      action: 'delete',
      currentMainMenuNumber: 38
    });
  }

  async moderatePlace(item) {
    const { data } = await this._axios.post('/ajax.php?action=moderatePlace', {
      id: item.id,
      currentMainMenuNumber: 38,
      newStatus: item.newStatus
    });
    return data;
  }

  async setDeliteEmail(item) {
    const { data } = await this._axios.post('/ajax.php?action=moderatePlace', {
      id: item.id,
      comment: item.comment,
      currentMainMenuNumber: 38,
      newStatus: -1
    });
  }
}
